<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div class="wrapper">
          <div class="title">
            <span>KAPMOBILE.ID</span>
          </div>

          <div class="sub-title">
            <span
              >Masukkan kode OTP yang telah<br />
              dikirimkan ke email
              <span class="bold">{{ email }}</span></span
            >
          </div>

          <div class="form">
            <div class="pesan" v-if="pesan">
              <span>{{ pesan }}</span>
            </div>
            <b-form-input
              v-model="otp"
              placeholder="Nomor OTP"
              type="number"
              class="inputs"
            ></b-form-input>

            <div class="button-wrapper">
              <b-button @click="doVerif" :disabled="loading == false"
                ><span v-if="loading === false">
                  verifikasi akun
                </span>
                <white-spinner v-else />
              </b-button>
            </div>
          </div>

          <div class="resend">
            <span
              >Email belum masuk?
              <span @click="doResend" class="do">kirim ulang <br /></span
            ></span>
          </div>

          <div class="modal">
            <b-modal
              hide-footer
              hide-header
              centered
              id="modal-success"
              title="Logout"
            >
              <div
                class="contents"
                style="display: flex; align-items: center;justify-content;center; padding:1% 4%;"
              >
                <span
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 28px;
                    font-weight: 700;
                    color: #061737;
                  "
                  >Akunmu berhasil diverifikasi</span
                >
                <img src="../../assets/vector/checked.svg" alt="" />
              </div>

              <div
                class="button-wrapper"
                style="display: flex; width: 100%; justify-content: center"
              >
                <b-button
                  @click="() => this.$router.push('/main_dashboard')"
                  style="
                    background-color: #327ff2;
                    color: #fff;
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    width: 25%;
                    border: none;
                    margin-top: 20px;
                    height: 40px;
                  "
                  >Ya</b-button
                >
              </div>
            </b-modal>

            <b-modal hide-footer hide-header centered id="modal-resend">
              <div class="contents" style="text-align: center; padding: 1% 4%">
                <span
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 28px;
                    font-weight: 700;
                    color: #061737;
                  "
                  >Kode OTP sedang dikirim ke email anda</span
                >
              </div>

              <div
                class="button-wrapper"
                style="display: flex; width: 100%; justify-content: center"
              >
                <b-button
                  style="background-color: #327ff2; width: 25%; height: 47px"
                  disabled
                >
                  <white-spinner />
                </b-button>
              </div>
            </b-modal>

            <b-modal hide-footer hide-header centered id="modal-sent">
              <div
                class="contents"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 1% 4%;
                "
              >
                <span
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 28px;
                    font-weight: 700;
                    color: #061737;
                  "
                  >Silahkan check email Anda</span
                >
                <img src="../../assets/vector/checked.svg" alt="" />
              </div>

              <div
                class="button-wrapper"
                style="display: flex; width: 100%; justify-content: center"
              >
                <b-button
                  @click="closeModal"
                  style="
                    background-color: #327ff2;
                    color: #fff;
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    width: 25%;
                    border: none;
                    margin-top: 20px;
                    height: 40px;
                  "
                  >Ya</b-button
                >
              </div>
            </b-modal>
          </div>
        </div>
      </b-col>
      <b-col style="padding: 0">
        <div class="wrapper2">
          <!-- <div class="ads">iklan</div>
          <div class="ads">iklan</div>
          <div class="ads">iklan</div> -->
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "@/ip_config.js";
import whiteSpinner from "../../components/spinner/white-spinner.vue";

export default {
  components: { whiteSpinner },
  data: "OTP",
  data() {
    return {
      id: "",
      otp: "",
      pesan: "",
      email: "",
      loading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    tes() {},

    async getData() {
      let vm = this;
      let x = localStorage.getItem("token");

      let profile = await axios.get("users/profile", {
        headers: {
          token: x,
        },
      });
      vm.id = profile.data[0].id;
      vm.email = profile.data[0].email;
    },

    doVerif() {
      let vm = this;
      vm.loading = true;
      let x = localStorage.getItem("token");

      axios
        .post(
          "OTP/verifikasi2",
          {
            kode: vm.otp,
            id: vm.id,
          },
          {
            headers: {
              token: x,
            },
          }
        )
        .then((res) => {
          if (res.data.message == "kodeOTP salah") {
            vm.loading = false;
            vm.pesan = "Kode OTP salah! Silahkan check kembali email anda";
          } else {
            vm.loading = false;
            this.$root.$emit("bv::show::modal", "modal-success");
          }
        });
    },

    async doResend() {
      this.$root.$emit("bv::show::modal", "modal-resend");
      let vm = this;
      let x = localStorage.getItem("token");
      await axios
        .post(
          "kirimUlangOTP2",
          {},
          {
            headers: {
              token: x,
            },
          }
        )
        .then(() => {
          this.$root.$emit("bv::hide::modal", "modal-resend");
          this.$root.$emit("bv::show::modal", "modal-sent");
        });
    },

    closeModal() {
      this.$root.$emit("bv::hide::modal", "modal-sent");
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white2": #fff,
);

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  .title {
    font-family: "nunito", sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: map-get($color, "blue");
  }
  .sub-title {
    font-family: "nunito", sans-serif;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    margin-top: 2%;
    .bold {
      font-weight: 700;
    }
  }

  .form {
    margin-top: 2%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .pesan {
      font-family: "nunito ", sans-serif;
      font-weight: 500;
      color: map-get($color, "red");
    }
    .inputs {
      height: 57px;
      font-family: "nunito", sans-serif;
      font-weight: 700;
      font-size: 16px;
      width: 50%;
      margin: 2% 0;
      border-radius: 6px;
      text-align: center;
    }
    .button-wrapper {
      width: 100%;
      margin-top: 3%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .btn {
        width: 50%;
        height: 57px;
        font-family: "nunito", sans-serif;
        font-weight: 700;
        text-transform: capitalize;
        font-size: 16px;
        border-radius: 6px;
        background-color: map-get($color, "blue");
        border: 1px solid map-get($color, "blue");
        box-shadow: 0px 20px 25px -10px rgba(15, 93, 210, 0.25);
      }
      .sign-in {
        font-family: "nunito", sans-serif;
        text-transform: capitalize;
        color: map-get($color, "black");
        font-weight: 300;
        font-size: 20px;
        margin-top: 8%;
        .biru {
          color: map-get($color, "blue");
          cursor: pointer;
          font-weight: 700;
          text-transform: capitalize;
        }
      }
    }
  }
  .resend {
    margin-top: 4%;
    font-family: "nunito", sans-serif;
    color: map-get($color, "black");
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    .do {
      color: map-get($color, "blue");
      font-weight: 700;
      text-transform: capitalize;
      cursor: pointer;
    }
    .note {
      color: map-get($color, "red");
    }
  }
}

.wrapper2 {
  background-image: url("../../assets/vector/Pattern.svg");
  background-position: center;
  background-repeat: repeat;
  background-blend-mode: soft-light;
  background-color: #327ff2;
  height: 100vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  .ads {
    width: 80%;
    height: 25%;
    border-radius: 10px;
    background-color: map-get($color, "white2");
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
